/**
 * 获取格式化时间
 */
export function getFormatTime(date) {
    const date1 = new Date(date);
    const y = date1.getFullYear();
    let m = date1.getMonth() + 1;
    m = m < 10 ? "0" + m : m;
    let d = date1.getDate();
    d = d < 10 ? "0" + d : d;
    return y + "-" + m + "-" + d;
}
/**
     *复制
     */
export function copy(value) {
    let oInput = document.createElement("input");
    oInput.value = value;
    document.body.appendChild(oInput);
    oInput.select(); // 选择对象
    document.execCommand("Copy"); // 执行浏览器复制命令
    oInput.className = "oInput";
    oInput.style.display = "none";
    console.log("copy:" + value);
}
/**
 * @name 判断是否是手机端
 * @returns 
 */
export function isMobile() {
    let userAgentInfo = navigator.userAgent;
    let Agents = ['Android', 'iPhone', 'SymbianOS', 'Windows Phone', 'iPad', 'iPod'];
    let getArr = Agents.filter(i => userAgentInfo.includes(i));
    return getArr.length ? true : false;
}

