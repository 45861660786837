<template>
  <div style="user-select: none">
    <template v-if="whitePage.indexOf($route.path) > -1">
      <router-view></router-view>
    </template>
    <template v-else>
      <!-- 顶部 -->
      <div class="content-header">
        <div class="left-logo">
          <img :src="require(`./assets/logo.png`)" class="logo-img" />
          <span v-if="!isMobile()">妙爪印宠物工作站</span>
        </div>
        <div class="right-info" v-if="userId">
          <!-- 下拉框 -->
          <el-dropdown @command="handleCommand">
            <div class="right-info-box">
              <img :src="require(`./assets/avater.png`)" class="avater-img" />
              <span>{{ realName }}</span>
            </div>
            <el-dropdown-menu slot="dropdown">
              <el-dropdown-item command="loginOut">退出登录</el-dropdown-item>
              <el-dropdown-item command="edit">修改密码</el-dropdown-item>
            </el-dropdown-menu>
          </el-dropdown>
        </div>
      </div>
      <el-container class="content-box" :style="contentStyle">
        <router-view></router-view>
      </el-container>
      <el-footer style="height：100px;" v-if="!isMobile()">
        <div class="footer">
          <div class="code-box">
            <span>联系我们：</span>
            <img :src="require(`./assets/code.png`)" class="code-img" />
          </div>
          <div class="bei-an">
            <a target="_blank" href="https://beian.miit.gov.cn">皖ICP备2022013154号-1</a>
            <a target="_blank" href="http://www.beian.gov.cn/portal/registerSystemInfo?recordcode=34010402703825">
              <img src="http://mzfile.miaozhuayin.top/resources/logo/police.png " style="margin:0 10px;" />
              <span>皖公网安备34010402703825</span>
            </a>
          </div>
        </div>
      </el-footer>
      <!-- 修改密码弹窗 -->
      <el-dialog title="修改密码" :visible.sync="isShowEditPasswordModal" width="30%" :before-close="handleClose">
        <el-form ref="formRef" :model="ruleForm" :rules="rules" label-width="120px">
          <!-- <el-form-item label="手机号" prop="mobile">
          <el-input
            v-model="ruleForm.mobile"
            placeholder="请输入手机号"
          ></el-input>
        </el-form-item> -->
          <el-form-item label="密码" prop="password">
            <el-input v-model="ruleForm.password" placeholder="请输入6-16位数字和字母组合" show-password></el-input>
          </el-form-item>
          <div style="color: red">
            请注意：若未输入新密码，系统将默认重置为'111111'，请知悉！
          </div>
        </el-form>
        <span slot="footer" class="dialog-footer">
          <el-button @click="handleClose">取 消</el-button>
          <el-button type="primary" @click="sureEdit">确 定</el-button>
        </span>
      </el-dialog>
    </template>

  </div>
</template>

<script>
import { resetPwd } from "@/service/api/base.js";
import { getCookie, removeCookie } from "@/utils/auth";
import md5 from "js-md5"; //全局引入
import { isMobile } from "@/utils/common";
export default {
  name: "App",
  data() {
    const validatePassword = (rule, value, callback) => {
      if (!value.length) {
        callback(new Error("密码不能为空"));
      } else {
        callback();
      }
    };
    /**
     * @name: 校验用户密码再次输入是否一致
     * @param rule 校验规则
     * @param value 当前值
     * @param callback 回调
     */
    const validatorRePassword = (rule, value, callback) => {
      if (value == "") {
        callback(new Error("请再次输入密码"));
      } else {
        if (value != this.ruleForm.password) {
          callback(new Error("确认密码和密码不一致，请重新输入"));
        } else {
          callback();
        }
      }
    };
    return {
      userId: this.$store.state.user.userInfo.id || getCookie("userId"),
      realName:
        this.$store.state.user.userInfo.realName || getCookie("realName"),
      isShowEditPasswordModal: false,
      ruleForm: {
        password: "",
        repassword: "",
        mobile: "",
        userType: 99,
      },
      contentStyle: {
        height: '0px'
      },
      rules: {
        password: [
          {
            required: true,
            validator: validatePassword,
            trigger: "blur",
          },
        ],
      },
      whitePage: ['/sterpackage']
    };
  },
  watch: {
    $route(to, from) {
      this.userId = this.$store.state.user.userInfo.id || getCookie("userId");
      this.realName =
        this.$store.state.user.userInfo.realName || getCookie("realName");
    },
  },
  methods: {
    isMobile() {
      return isMobile()
    },
    /**
     * @name 下拉框命令
     */
    handleCommand(name) {
      switch (name) {
        case "edit":
          // 修改密码
          this.isShowEditPasswordModal = true;
          brea;
        case "loginOut":
          removeCookie("userId");
          removeCookie("realName");
          window.location.reload();
          break;
      }
    },
    /**
     * @name 取消修改密码
     */
    handleClose() {
      this.isShowEditPasswordModal = false;
    },
    /**
     * @name 确认修改密码
     */
    async sureEdit() {
      let password = this.ruleForm.password == '' ? '111111' : this.ruleForm.password.trim();
      let params = {
        userId: this.userId,
        pwd: md5(password),
      };
      const res = await resetPwd(params);
      this.ruleForm.password = "";
      this.handleClose();
    },
  },
  mounted() {
    this.contentStyle.height =
      document.documentElement.clientHeight - 221 + "px";
  },
};
</script>

<style>
.content-header {
  display: flex;
  justify-content: space-between;
  height: 80px;
  line-height: 80px;
  font-size: 20px;
  font-weight: bold;
}

.left-logo {
  display: flex;
}

.logo-img {
  width: 140px;
  margin-left: 30px;
  margin-right: -15px;
}

.right-info {
  margin-right: 30px;
  font-size: 16px;
  font-weight: 400;
}

.right-info-box {
  display: flex;
  align-items: center;
}

.avater-img {
  width: 48px;
  height: 48px;
  margin-right: 5px;
}

.footer {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  min-height: 100px;
  padding: 20px 0;
  background: #000;
  color: #fff;
}

a:-webkit-any-link {
  color: #fff;
  cursor: pointer;
  text-decoration: underline;
}

.el-footer {
  padding: 0 !important;
}

.code-box {
  position: absolute;
  left: 50px;
  top: 30px;
  display: flex;
  margin-left: 250px;
}

.code-img {
  width: 100px;
  height: 100px;
  cursor: pointer;
}

.content-box {
  height: calc(100% - 220px);
  background: #fafafa;
}

.bei-an {
  display: flex;
  align-items: center;
}
</style>
