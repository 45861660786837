// import router from '@/router'
// eslint-disable-next-line no-unused-vars
import { Message, MessageBox, Loading } from 'element-ui'// 按需引入element中所需的组件
import Qs from 'qs'// 引入qs
import axios from 'axios'
import store from '@/store'

// const BASE_URL = "http://39.96.76.155:8080"
const BASE_URL = "https://miaozhuayin.top/api"

const service = axios.create({
  withCredentials: true,
  timeout: 5000,
  // 自定义的请求头
  headers: {
    post: {
      'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8'
    },
    'X-Requested-Width': 'XMLHttpRequest',
    token: null
  },
})

service.interceptors.request.use(
  config => {
    const token = store.getters.token
    token && (config.headers.Authorization = token)

    // 在发送请求之前做处理...
    config.headers = Object.assign(config.method === 'get' ? {
      'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8'
    } :   {
    }  , config.headers)

    if (config.method === 'post') {
          config.data = Qs.stringify(config.data)
    }
    return config
  },
  error => {
    // do something with request error
    console.error(error) // for debug
    return Promise.reject(error)
  }
)

// response interceptor
service.interceptors.response.use(
  response => {
    console.log('response',response);
    const res = response.data

    if (response.status !== 200) {
      showTips('网络异常,请重试!')
      return Promise.reject(new Error(res.message || 'Error'))
    }
    if (res.code >= 1) {
      return res
    } else {
      // showTips(res.msg)
      return Promise.reject(res)
    }
  },
  error => {
    console.log('err' , error.response.data.message) // for debug
    // http状态码等问题处理
    switch (error.response.status) {
        case 400:
            error.message = `请求错误`;
            break;
        case 403:
            error.message = `拒绝访问`;
            break;
        case 404:
            error.message = `请求地址出错`;
            break;

        default:
    }
    showTips(error.message || error)
    return Promise.reject(error)
  }
)

const showTips = msg => {
  Message({
    message: msg,
    type: 'error',
    duration: 2 * 1000
  })
}


export const POST = (url, params,baseUrl = BASE_URL) => {
  const options = {
    method: 'post',
    url: baseUrl + url,
    data: params
  }
  return service(options)
}

export const GET = (url, params,baseUrl = BASE_URL) => {
  const options = {
    method: 'get',
    url:  baseUrl + url,
    data: params
  }
  return service(options)
}

export default service

