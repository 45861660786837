/**
 * description: 通用接口
 **/
import * as API from '../index'
//登录
export const Login = (params = {}) => API.POST('/mz/baselogin/login', params)
//注册
export const registerUser = (params = {}) => API.POST('/mz/baselogin/registerUser', params)
//表格
export const mzyList = (params = {}) => API.POST('/mz/footmark/list', params)
//审核
export const auditApply = (params = {}) => API.POST('/mz/footmark/auditApply', params)
//重置密码
export const resetPwd = (params = {}) => API.POST('/mz/baselogin/resetPwd', params)
//新增送养信息
export const createApply = (params = {}) => API.POST('/mz/footmark/createApply', params)
//黑名单
export const getReportedUserList = (params = {}) => API.POST('/mz/baselogin/getReportedUserList', params)
//黑名单审核
export const saveAuditResult = (params = {}) => API.POST('/mz/baselogin/saveAuditResult', params)
//举报
export const reportedUser = (params = {}) => API.POST('/mz/baselogin/reportedUser', params)
//保存banner
export const saveImg = (params = {}) => API.POST('/mz/footmark/saveImg', params)
//获取banner
export const getImgList = (params = {}) => API.POST('/mz/footmark/getImgList', params)
//删除banner
export const delImg = (params = {}) => API.POST('/mz/footmark/delImg', params)
//投诉与反馈列表
export const getFeedbackList = (params = {}) => API.POST('/mz/footmark/getFeedbackList', params)
//处理投诉与反馈
export const updateFeedback = (params = {}) => API.POST('/mz/footmark/updateFeedback', params)
//删除信息
export const delPetReport = (params = {}) => API.POST('/mz/footmark/delPetReport', params)
//统计数据
export const statPetReport = (params = {}) => API.POST('/mz/footmark/statPetReport', params)

