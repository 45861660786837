import Vue from "vue";
import VueRouter from "vue-router";
import store from "@/store";
import { getCookie } from "@/utils/auth";
Vue.use(VueRouter);
//获取原型对象上的push函数
const originalPush = VueRouter.prototype.push
//修改原型对象中的push方法
VueRouter.prototype.push = function push(location) {
  return originalPush.call(this, location).catch(err => err)
}
const routes = [
  {
    path: "/",
    redirect: "login",
  },
  {
    path: "/login",
    name: "login",
    component: resolve =>
      require([
        "../views/mzyLogin.vue"
      ], resolve)
  },
  {
    path: "/main",
    name: "main",
    redirect: '/placeoutadopt',
    component: resolve =>
      require([
        "../views/mzyMain.vue"
      ], resolve),
    children: [
      {
        path: "/adopt",
        name: "adopt",
        component: resolve =>
          require([
            "../views/mzyAdopt.vue"
          ], resolve)
      },
      {
        path: "/placeoutadopt",
        name: "placeoutadopt",
        component: resolve =>
          require([
            "../views/placeOutAdopt.vue"
          ], resolve)
      },
      {
        path: "/seek",
        name: "seek",
        component: resolve =>
          require([
            "../views/mzySeek.vue"
          ], resolve)
      },
      {
        path: "/donation",
        name: "donation",
        component: resolve =>
          require([
            "../views/mzyDonation.vue"
          ], resolve)
      },
      {
        path: "/save",
        name: "save",
        component: resolve =>
          require([
            "../views/mzySave.vue"
          ], resolve)
      },
      {
        path: "/blacklist",
        name: "blacklist",
        component: resolve =>
          require([
            "../views/blacklist.vue"
          ], resolve)
      },
      {
        path: "/banner",
        name: "banner",
        component: resolve =>
          require([
            "../views/bannerUpload.vue"
          ], resolve)
      },
      {
        path: "/feedback",
        name: "feedback",
        component: resolve =>
          require([
            "../views/feedBack.vue"
          ], resolve)
      },
      {
        path: "/datastatis",
        name: "datastatis",
        component: resolve =>
          require([
            "../views/dataStatis.vue"
          ], resolve)
      },
    ]
  },
  {
    path: "/sterpackage",
    name: "sterpackage",
    component: resolve =>
      require([
        "../views/sterPackage/index.vue"
      ], resolve)
  },
];

const router = new VueRouter({
  mode: "history",
  base: "/",
  routes
});
router.beforeEach((to, from, next) => {
    let userId = store.state.user.userInfo.id || getCookie('userId');
    console.log('用户id：' + userId)
    if (to.path == '/login') {
      next();
    } else {
      if (userId) {
        next();
        return;
      } else {
        next({ path: '/login' });
        return;
      }
    }
});
export default router;
