import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import "./assets/icon/iconfont.css";
// 使用symbol
import "./assets/icon/iconfont.js";
import VueTouch from 'vue-touch'

// 引入echarts
import echarts from "@/echarts";


import { createApp } from 'vue';
import { Form, Field, CellGroup } from 'vant';
import pom from '@/utils/pcOrMobile' // 引入pc与mobile检测文件
import '@/utils/rem' // 引入移动端rem适配
Vue.use(pom) // 插件增强vue功能
Vue.use(Form);
Vue.use(Field);
Vue.use(CellGroup);


 
// 挂载到vue实例中
Vue.prototype.$echarts = echarts
Vue.config.productionTip = false
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
Vue.use(ElementUI);
Vue.use(VueTouch, { name: 'v-touch' })
new Vue({
  render: h => h(App),
  router,
  store
}).$mount('#app')
