import { Login} from "@/service/api/base";
import {
  getCookie,
  setCookie,
  removeCookie,
} from "@/utils/auth";
import { resetRouter } from "@/router";

const getDefaultState = () => {
  return {
    // token: getToken(),
    userInfo: JSON.parse(getCookie('userInfo') || '{}') ,
  };
};

const state = getDefaultState();

const mutations = {
  RESET_STATE: (state) => {
    Object.assign(state, getDefaultState());
  },
  SET_TOKEN: (state, token) => {
    state.token = token;
  },
  SET_USER_INFO: (state, userInfo) => {
    state.userInfo = userInfo;
  },
  SET_NAME: (state, name) => {
    state.name = name;
  },
  SET_USER_ID: (state, userId) => {
    state.userId = userId;
  },
  SET_AVATAR: (state, avatar) => {
    state.avatar = avatar;
  },
};

const actions = {
  // user login
  login({ commit }, userInfo) {
    //登录
    return new Promise((resolve, reject) => {
      Login(userInfo)
        .then((res) => {
          console.log(res);
          if (res.code == 1) {
            commit('SET_USER_INFO',res.data);
            setCookie('userId',res.data.id)
            setCookie('realName',res.data.realName)
            setCookie('source',res.data.source)
            resolve(res);
          }else if(res.code == 2){
            resolve(res);
          } else {
            reject(res.msg);
          }
        })
        .catch((error) => {
          console.error(error);
          reject(error);
        });
    });
  },

  checkSso({ commit, state }, info) {
    //校验token
    return new Promise((resolve, reject) => {
      checkSso({ tokenId: state.token, domain: info.domain, appId: info.appId })
        .then((res) => {
          console.log(res);
          if (res.code == 1) {
            const result = res.data;
            commit("SET_TOKEN", state.token);
            // commit('SET_USER_INFO',state.userInfo)
            setToken(state.token);
            // setCookie('userInfo', result.userInfo)
            resolve(result);
          } else {
            reject(res.msg);
          }
        })
        .catch((error) => {
          console.error(error);
          reject(error);
        });
    });
  },

  // user logout
  logout({ commit, state }) {
    return new Promise((resolve, reject) => {
      Logout({ tokenId: state.token })
        .then((res) => {
          // resetDefault(res)
          removeToken(); // must remove  token  first
          removeCookie("userInfo"); // must remove  userInfo
          commit("RESET_STATE");
          resolve(res);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },

  // remove token
  // resetDefault({ commit },res) {
  //   return new Promise(resolve => {
  //     removeToken() // must remove  token  first
  //     removeCookie('userInfo') // must remove  userInfo
  //     commit('RESET_STATE')
  //     resolve(res)
  //   })
  // }

  // get user info
  // getInfo({ commit, state }) {
  //   return new Promise((resolve, reject) => {
  //     getInfo(state.token).then(response => {
  //       const { data } = response
  //       if (!data) {
  //         return reject('Verification failed, please Login again.')
  //       }
  //       const { name, avatar } = data
  //       commit('SET_NAME', name)
  //       commit('SET_AVATAR', avatar)
  //       resolve(data)
  //     }).catch(error => {
  //       reject(error)
  //     })
  //   })
  // },
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
};
